import React from 'react';
import Backbone from 'backbone';
import $ from 'jquery';
import moment from 'moment';
import io from 'socket.io-client';
import config from './config.jsx';
import Selectize from 'selectize';

import InventoryLeagueCollection from './InventoryLeagueCollection.jsx';

import 'selectize/dist/css/selectize.css';
import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.selectizeCharacterControl = null;
        this.selectizeLeagueControl = null;

        this.characterComponent = null;
        this.leagueComponent = null;

        this.state = {
            lastUpdate: null,
            weaponSet: 0,
            model: new Backbone.Model(),
            leagues: new InventoryLeagueCollection(),
            characters: new Backbone.Collection(),
            items: new Backbone.Collection(),
            itemSlots: this.getItemSlots(),
            socket: io.connect(config.websocket, {
                forceNew: true,
                upgrade: false,
                secure: true,
                transports: ['websocket']
            })
        };
    }

    componentWillMount() {
        this.state.leagues.on('sync reset', function() {
            this.setState({
                lastUpdate: new Date().getTime()
            });
        }, this);

        this.state.items.on('sync reset', function() {
            this.setState({
                lastUpdate: new Date().getTime()
            });
        }, this);

        this.state.model.on('change:event', function() {
            if (this.state.model.get('event')) {
                this.state.characters.url = config.api + '/league/' + encodeURIComponent(this.state.model.get('event')) + '/characters';
                this.state.characters.fetch().then(() => {
                    this.setState({
                        lastUpdate: new Date().getTime()
                    });
                });
            } else {
                this.state.characters.reset();
            }
        }, this);

        this.state.model.on('change:character', function() {
            if (this.state.model.get('character')) {
                this.state.items.url = config.api + '/inventory/' + encodeURIComponent(this.state.model.get('character'));
                this.state.items.fetch().then(() => {
                    this.setState({
                        lastUpdate: new Date().getTime()
                    });
                });
            } else {
                this.state.items.reset();
            }
        }, this);
    }

    componentDidMount() {
        this.setupWebsocketHandlers();

        this.initSelectize();

        this.state.leagues.fetch();
    }

    componentWillUpdate() {
        this.destroyControls();
    }

    componentDidUpdate() {
        this.initSelectize();
    }

    componentWillUnmount() {
        this.state.leagues.off(null, null, this);
        this.state.items.off(null, null, this);
        this.state.model.off(null, null, this);
    }

    destroyControls() {
        if (this.selectizeLeagueControl) {
            this.selectizeLeagueControl[0].selectize.destroy();
            this.selectizeLeagueControl = null;
        }

        if (this.selectizeCharacterControl) {
            this.selectizeCharacterControl[0].selectize.destroy();
            this.selectizeCharacterControl = null;
        }
    }

    initSelectize() {
        this.initLeagueControl();
        this.initCharacterControl();
    }

    initLeagueControl() {
        if (!this.leagueComponent) return;

        this.selectizeLeagueControl = $(this.leagueComponent).selectize({
            maxItems: 1,
            allowEmptyOption: true,
            highlight: false,
            selectOnTab: true,
            onChange: this.setLeague.bind(this),
            closeAfterSelect: true,
            items: null,
            onDropdownClose: (dropdown) => {
                if(!dropdown) return;
                //$(dropdown).prev().find('input').blur();
            },
            onItemRemove: (value) => {
                console.log('removing league: ' + value);
                if(!value) return;
                this.state.model.set('event', null);
            }
        });

        if (this.state.model.get('event') && this.selectizeLeagueControl) {
            this.selectizeLeagueControl[0].selectize.setValue(this.state.model.get('event'), true);
        }
    }

    initCharacterControl() {
        if (!this.characterComponent) return;

        this.selectizeCharacterControl = $(this.characterComponent).selectize({
            maxItems: 1,
            allowEmptyOption: true,
            highlight: false,
            selectOnTab: true,
            onChange: this.setCharacter.bind(this),
            closeAfterSelect: true,
            items: null,
            onDropdownClose: (dropdown) => {
                if(!dropdown) return;
                //$(dropdown).prev().find('input').blur();
            },
            onItemRemove: (value) => {
                console.log('removing character: ' + value);
                if(!value) return;
                this.state.model.set('character', null);
            }
        });

        if (this.state.model.get('character') && this.selectizeCharacterControl) {
            this.selectizeCharacterControl[0].selectize.setValue(this.state.model.get('character'), true);
        }
    }

    setLeague(event) {
        this.state.model.set({
            event,
            character: null
        });
    }

    setCharacter(character) {
        this.state.model.set({
            character
        });
    }

    setupWebsocketHandlers() {
        // Push pagination on reconnection ...
        this.state.socket.on('reconnect', () => {
            // We need a delay here so that the websocket-server has time to put the socket into the array.
            // Might be able to remove this if we force-insert the socket into the array with the pagination event.
            setTimeout(() => {
                this.broadcastPagination();
            }, 5000);
        });

        // New data is served from the websocket server (based on pagination) ...
        this.state.socket.on('new ladder', (data) => {
            // 'data' is  received in JSON format ...
        });

        // A new ladder has been completed, update header ...
        this.state.socket.on('update ladder', (data) => {
            // 'data' is received as JSON string ...
            data = JSON.parse(data);

            // Update the ladder header ...
            this.state.ladder.set(data);
        });

        // The date when the selected ladder was last updated ...
        this.state.socket.on('previous ladder processing date', (date) => {
            this.state.ladder.set('processed', date);
        });
    }

    broadcastPagination() {
        return ;

        var paginationData = {
            page: 0,
            class: [],
            skill: '',
            name: '',
            caster: true
        };

        if (!this.state.ladder.get('name')) {
            return false;
        }

        paginationData.event = this.state.ladder.get('name');

        this.state.socket.emit('pagination', paginationData);
    }

    renderEventOptGroups() {
        let publicLeagues = this.state.leagues.where({ event: false, ended: false, private: false }),
            privateLeagues = this.state.leagues.where({ event: false, ended: false, private: true }),
            races = this.state.leagues.where({ event: true, ended: false, private: false }),
            archived = this.state.leagues.where({ ended: true });

        return [
            <optgroup label={'Races'} key={1}>
                {this.renderOptions(races)}
            </optgroup>,
            <optgroup label={'Public Leagues'} key={2}>
                {this.renderOptions(publicLeagues)}
            </optgroup>,
            <optgroup label={'Private Leagues'} key={3}>
                {this.renderOptions(privateLeagues)}
            </optgroup>,
            <optgroup label={'Archived Events'} key={4}>
                {this.renderOptions(archived)}
            </optgroup>
        ];
    }

    renderOptions(list) {
        if (!list.length) {
            return null;
        }

        return list.map((option) => {
            return (
                <option key={option.get('id')} value={option.get('id')}>
                    {option.get('id')}
                </option>
            );
        });
    }

    toggleWeaponSet() {
        this.setState({
            weaponSet: 1 - this.state.weaponSet
        });
    }

    getItemSlots() {
        return ['Helm', 'Amulet', 'BodyArmour', 'Gloves', 'Ring', 'Ring2', 'Belt', 'Boots'];
    }

    renderInventory() {
        console.log('items:' + this.state.items.length);

        if (!this.state.items.length) {
            return null;
        }

        let weaponSet = this.state.items.models.filter(item => {
            return item.get('inventoryId') === ('Weapon' + (this.state.weaponSet ? '2' : ''))
                || item.get('inventoryId') === ('Offhand' + (this.state.weaponSet ? '2' : ''));
        }).sort((a, b) => 0);

        let flasks = this.state.items.models.filter(item => {
            return item.get('inventoryId') === 'Flask';
        }).sort((a, b) => 0);

        let gear = this.state.items.models.filter(item => {
            return this.state.itemSlots.indexOf(item.get('inventoryId')) !== -1;
        }).sort((a, b) => 0);

        return [
            <div className="item-container">
                <div className="title">{'Weapon Set ' + (this.state.weaponSet + 1)}</div>
                {weaponSet.map(item => this.renderItem(item))}
                <span className="weapon-toggle" onClick={this.toggleWeaponSet.bind(this)}></span>
            </div>,
            <div className="item-container">
                <div className="title">{'Gear'}</div>
                {gear.map(item => this.renderItem(item))}
            </div>,
            <div className="item-container">
                <div className="title">{'Flasks'}</div>
                {flasks.map(item => this.renderItem(item))}
            </div>
        ];
    }

    renderItem(item) {
        let classes = ['inventory-item'];

        // Color
        switch(item.get('frameType')) {
            case 0: // white
                classes.push('normal');
                break;
            case 1:
                classes.push('magic');
                break;
            case 2:
                classes.push('rare');
                classes.push('double');
                break;
            case 3:
                classes.push('unique');
                classes.push('double');
                break;
        }

        return (
            <div className={classes.join(' ')}>
                <div className="header">
                    {classes.indexOf('double') >= 0 ? [<div>{item.get('name')}</div>, <div>{item.get('typeLine')}</div>] : <div>{item.get('typeLine')}</div>}
                </div>
                {item.get('mods').map(mod => {
                    let modType = this.resolveModType(mod),
                        modClasses = ['inventory-item-mod'];

                    modClasses.push(modType);

                    return (
                        <div className={modClasses.join(' ')}>
                            {mod.text}
                        </div>
                    );
                })}
            </div>
        );
    }

    resolveModType(mod) {
        if(mod.enchanted) return 'enchanted-mod';
        else if(mod.crafted) return 'crafted-mod';
        else if(mod.utility) return 'utility-mod';
        else if (mod.implicit) return 'implicit-mod';
        else return 'explicit-mod';
    }

    renderCharacters() {
        console.log('characters:' + this.state.characters.length);

        if (!this.state.characters.length) {
            return null;
        }

        return (
            <select style={{ width: '400px' }} className="characters" ref={component => this.characterComponent = component }>
                {this.state.characters.models.map(model => {
                    return (
                        <option key={model.get('character_id')}>{model.get('name')}</option>
                    );
                })}
            </select>
        );
    }

    render() {
        return (
            <div>
                <div className="top">
                    <select style={{ width: '400px' }} className="events" ref={component => this.leagueComponent = component }>
                        {this.renderEventOptGroups()}
                    </select>
                    {this.renderCharacters()}
                    {this.renderInventory()}
                </div>
            </div>
        );
    }
}

App.defaultProps = {};

export default App;
